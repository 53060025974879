<template>
  <div>
    <v-toolbar elevation="0">
      <v-toolbar-title data-cy="title" class="hqsOrange--text text">
        {{ headerTitle }}
      </v-toolbar-title>

      <div>
        <v-menu offset-y v-if="hasMenu && menuList.length > 0">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              data-cy="create_menu"
              v-bind="attrs"
              v-on="on"
              depressed
              outlined
              fab
              x-small
              color="hqsOrange"
              class="ml-5"
            >
              <v-icon>{{ icon }}</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="item in menuList"
              :key="item.title"
              :data-cy="item.dataCy"
              :to="item.path"
              :disabled="item.disabled"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          v-if="hasMenu && menuList.length === 0"
          data-cy="create"
          :href="href"
          depressed
          outlined
          fab
          x-small
          color="hqsOrange"
          class="ml-5"
          :to="to"
          @click="emitEvent"
        >
          <v-icon>{{ icon }}</v-icon>
        </v-btn>
      </div>
      <v-spacer />
    </v-toolbar>
    <p class="darkGrey--text text-body-2 ml-4">{{ subHeader }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },

  props: {
    headerTitle: String,
    hasMenu: Boolean,
    menuList: Array,
    href: String,
    to: Object,
    icon: String,
    event: String,
    subHeader: String,
  },

  methods: {
    emitEvent() {
      if (this.event) {
        this.$emit(this.event)
      }
    },
  },

}
</script>

<style lang="scss" scoped></style>
